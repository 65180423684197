var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"common_wrap"},[_c('div',{staticClass:"order_page_wrap"},[_vm._m(0),_c('div',{staticClass:"order_main_wrap"},[_c('div',{staticClass:"block_wrap"},[_c('div',{staticClass:"infotable_wrap"},[_c('table',{staticClass:"underbox"},[_vm._m(1),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm._f("unix2date")(_vm.header.status.registered_on))))])])]),_c('table',[_vm._m(2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("plan")(_vm.header.status.plan)))])])]),_c('table',[_vm._m(3),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.header.branch_num))])])]),_c('table',[_vm._m(4),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("use_status")(_vm.header.status.use_status)))])])]),_c('table',[_vm._m(5),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("pay_method")(_vm.header.status.pay_method))),(_vm.header.status.pay_method !== null)?_c('div',{staticClass:"common_button"},[_c('common-button',{attrs:{"type":"blue"},on:{"clicked":function($event){return _vm.change_order()}}},[_vm._v("お支払い方法変更")])],1):_vm._e()])])]),_c('table',[_vm._m(6),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.util.is_opt[_vm.header.status.newsletter])),_c('span',{staticClass:"newsletter_toggle"},[_c('common-toggle',{model:{value:(_vm.tmp_newsletter),callback:function ($$v) {_vm.tmp_newsletter=$$v},expression:"tmp_newsletter"}})],1)])])]),(_vm.header.status.use_status === 0)?_c('table',[_vm._m(7),_c('tr',[_c('td',[_c('div',{staticClass:"common_button"},[_c('common-button',{attrs:{"type":"red"},on:{"clicked":function($event){return _vm.m.cancelUse({ company_code: _vm.s_company_code })}}},[_vm._v("解約")])],1)])])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"order_title_wrap"},[_c('h2',{staticClass:"page_title other"},[_vm._v("ご契約状況")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("使用開始日")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("プラン")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("店舗数")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("使用状況")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("お支払い方法")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("メルマガ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("解約")])])
}]

export { render, staticRenderFns }