import { render, staticRenderFns } from "./Statusold.vue?vue&type=template&id=ea7c05fa&scoped=true&lang=pug"
import script from "./Statusold.vue?vue&type=script&lang=ts"
export * from "./Statusold.vue?vue&type=script&lang=ts"
import style0 from "./Statusold.vue?vue&type=style&index=0&id=ea7c05fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea7c05fa",
  null
  
)

export default component.exports