
  import { Component, Mixins } from "vue-property-decorator";
  import mixinOrder from "@/mixins/mixinOrder";
  import CommonButton from "@/components/common/Button.vue";
  import CommonToggle from "@/components/common/Toggle.vue";

  @Component({
    components: {
      CommonButton,
      CommonToggle,
    },
  })
  export default class Order extends Mixins(mixinOrder) {
    get tmp_newsletter() {
      return this.header.status.newsletter == 1;
    }
    set tmp_newsletter(val) {
      if (val) {
        this.m.startNewsletter();
      } else {
        this.m.stopNewsletter();
      }
    }
    change_order() {
      this.change_page("OrderChangePayMethod");
    }
  }
